.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: Catamaran;
  src: url(./Catamaran-Thin.ttf)
}

@font-face {
  font-family: FreeSansBold;
  src: url(./FreeSansBold-Xgdd.ttf)
}

.mobileimagetext{
  color: white;
  position: absolute;
  top: 35%;
  left: 3%;
  font-size: 8vw;
  font-family: Catamaran;
} 

.desktopimagetext{
  color: white;
  position: absolute;
  top: 30%;
  left: 10%;
  font-size: 4vw;
  font-family: Catamaran;
} 

 @media only screen and (min-width: 600px) {
   .mobilebanner {
     height: 0;
   }

   .desktopbanner {
     height: 100%;
   }

   .mobileimagetext {
     visibility: hidden;
   }

   .desktopimagetext {
     visibility: visible;
   }
   .girlfriendpic {
     width: 33%;
     float: left;
     padding: 10px 2vw 0px 2vw;
  }

  .aboutmetext {
    height: 20.5vw;
    width: 25%;
    float: left;
    background-color: #202020;
  }
  
  .aboutmebannerpic {
    float: left;
    width: 75%;
  }

.aboutmebanner {
  margin: 10px 8vw 10px 8vw;
  overflow: hidden;
}
}

@media only screen and (max-width: 600px) {
   .mobilebanner {
     height: 100%;
   }

   .desktopbanner {
     height: 0;
   }

   .mobileimagetext {
     visibility: visible;
   }

   .desktopimagetext {
     visibility: hidden;
   }
   .girlfriendpic {
      width: 80%;
      float: center;
      padding: 10px 10px;
    }

  .aboutmetext {
    height: 10vw;
    width: 100%;
    float: left;
    background-color: #202020;
  }
  
  .aboutmebannerpic {
    float: left;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 10px;
    background-color: #202020;
  }

.aboutmebanner {
  margin: 10px 8vw 0px 8vw;
  overflow: hidden;
}
}

.mobileimagecontainer {
  position: relative;
  text-align: left;
  color: white;
}

.desktopimagecontainer {
  position: relative;
  text-align: left;
  color: white;
}

.cardcontainer {
  position: relative;
  background-color: #282c34;
  width: 100%;
  height: 100%;
}

.cardcontainer > .card {
  display: inline-block;
  color: white;
  min-width: 250px;
  width: 25%;
  min-width: 350px;
  vertical-align: top;   
  bottom: 30px;
  margin: 10px 3%;
}

.card2 > .card {
  bottom: -10px;
}

.card3 > .card {
  top: 10vh;
  width: 17%
}

.card4 > .card {
  position: relative;
  bottom: 15vw;
}

.card5 > .card {
  bottom: 0px;
}

.buttonspace {
  margin: 0px 10px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.center {
  font-family: FreeSansBold;
  color: white;
  font-size: 4vw;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.test {
  position: relative;
  top: 50%;
}

.underbannertext {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  background-color: #202020;
  height: 100%;
  margin: 0px 8vw 10px 8vw;
  padding: 10px 20px 10px 20px;
  text-align: left;
}

.underbannertext2 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
  background-color: #202020;
  height: 100%;
  margin: 10px 8vw 10px 8vw;
  padding: 10px 20px 10px 20px;
  text-align: left;
}

.lowerbanner {
  background-color: #202020;
  margin: 10px 8vw 10px 8vw;
}


.sidegirlfrienddiv {
  width: 100%;
  height: 30vw;
  color: white;
  padding: 10px 20px 10px 20px;
  text-align: left;
  float: left;
  background-color: #282c34;
}

.textandquote {
  background-color: #282c34;
  margin: 0px;
  padding-bottom: 20%;
}

.lowertext {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: large;
  background-color: #202020;
  text-align: left;
  min-height: 24vw;
  padding: 10px 20px 10px 20px;
  color: white;
}

.quote {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 20px 40px;
  margin: 10px 0px 0px 0px;
  background-color: #202020;
  text-align: left;
  color: white;
  float: left;
}

.infotext {
  margin: 0px;
  font-size: large;
}

.breadcrumb1 {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 0px;
  list-style: none;
  background-color: #202020;
  border-radius: .25rem;
}

.list-group-item1 {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #464646;
  border: 1px solid rgba(0,0,0,.125);
}

.blackbackground {
  background-color: rgba(0,0,0,0.5);
  border-radius: 20px;
  padding: 10px;
  font-family: FreeSansBold;
  font-size: 3vw;
}

.desktopimagetext2 {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  color: white;
}

.test {
  position: relative;
}

.test2 {
  width: 100vw;
}

img {
    pointer-events: none;
}